//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import AuthManager from "@/components/Setting/module/AuthManager"
import EmployeeManager from "@/components/Setting/module/EmployeeManager"

export default {
  name: "Index",
  mounted() {
    this.$nextTick(function () {
      // this.request_http_session_account_list();
    });
  },
  components: {
    AuthManager, EmployeeManager
  },
  data() {
    return {
      currentTabComponent: "AuthManager",
      formInline: {
        user: '',
        region: ''
      },
      page: 1,
      page_size: 30,
      count: 0,
      account_list: [
        {name: "授权管理", component: "AuthManager"},
        {name: "员工管理", component: "EmployeeManager"},
        // {name: "关联设置", component: "RelevanceManager"},
      ],
      active_index: 0,
    }
  },

  methods: {
    on_select_account(row, index) {
      this.active_index = index;
      this.currentTabComponent = row.component;
    }
  }
}
