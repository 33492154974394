//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  http_setting_user_list,
  http_setting_user_add,
  http_setting_user_update,
  http_setting_user_delete,
  http_setting_user_account_list,
  http_setting_user_account_well
} from "@/api/setting.js"

export default {
  name: "AuthManager",
  data() {
    return {
      dialogVisible: false,
      page: 1,
      page_size: 10,
      count: 0,
      tableData: [],
      action_index: -1,
      title: "添加员工",
      userinfo: {
        id: 0,
        name: "",
        username: "",
        password: "",
        role: "user",
        status: true
      },
      preg_phone: /^1[3-9]\d{9}$/,
      dialogAllocationVisible: false,
      allocationList: [],
      allocationUpdateList: [],
      active_user_info: {}
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.request_http_setting_load_user_list();
    })
  },
  methods: {
    request_http_setting_load_user_list() {
      http_setting_user_list({page: this.page, page_size: this.page_size}).then(res => {
        if (res.code == 0) {
          this.tableData = res.data.data;
          this.count = res.data.count;
        }
      })
    },
    onAddEmployee() {
      this.init_userinfo();
      this.dialogVisible = true;
      this.title = "添加员工";
    },
    handleEdit(row, index) {
      this.init_userinfo();
      this.dialogVisible = true;
      this.userinfo = JSON.parse(JSON.stringify(row));
      this.userinfo.password = "";
      this.title = "编辑员工";
      this.action_index = index;
    },
    onSubmit() {


      let userinfo = this.userinfo;
      if (userinfo.name.length <= 0) {
        this.$message.error("姓名不能为空");
        return false;
      }
      if (!this.preg_phone.test(userinfo.username)) {
        this.$message.error("登录手机号码格式不对");
        return false;
      }
      if (userinfo.id <= 0) {
        if (userinfo.password.length < 6) {
          this.$message.error("登录密码不能低于6位");
          return false;
        }
      }

      userinfo.status = userinfo.status ? 'Y' : 'N';
      if (userinfo.id <= 0) {
        http_setting_user_add(userinfo).then(res => {
          if (res.code == 0) {
            this.dialogVisible = false;
            this.tableData.unshift(res.data);
            this.count++;
          }
        });
      } else {
        console.log(userinfo);
        http_setting_user_update(userinfo).then(res => {
          if (res.code == 0) {
            this.dialogVisible = false;
            this.$set(this.tableData, this.action_index, res.data);
            this.action_index = -1;
          }
        })
      }
    },
    init_userinfo() {
      this.userinfo = {id: 0, name: "", username: "", password: "", role: "user", status: true}
    },
    handleDelete(row, index) {
      this.$confirm('此操作将永久删除该账号, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        http_setting_user_delete(row).then(res => {
          if (res.code == 0) {
            this.tableData.splice(index, 1);
            this.count--;
          }
        })
      }).catch(() => {
      });
    },
    onRquestUserAccountList(user_id) {
      http_setting_user_account_list({user_id}).then(res => {
        if (res.code == 0) {
          this.allocationList = res.data;
        }
      })
    },
    handleAllocation(row, index) {
      this.dialogAllocationVisible = true;
      this.active_user_info = row;
      this.onRquestUserAccountList(row.id);
    },

    onChangePage(page) {
      console.log(page);
      this.page = page;
      this.request_http_setting_load_user_list();
    },
    changeBox(row, index) {
      console.log(row, index);
      let request_body = {};
      //is_select
      if (row.is_select) {
        request_body.active = 1;
      } else {
        request_body.active = -1;
      }
      request_body.user_id = this.active_user_info.id;
      request_body.account_id = row.open_id;
      http_setting_user_account_well(request_body).then(res => {
        console.log(res);
      })
    }

  }
}
