//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  http_setting_load_auth_list,
  http_setting_delete_auth_list,
  http_setting_add_auth_list,
  http_setting_add_auth_info
} from "@/api/setting.js"

export default {
  name: "AuthManager",
  data() {
    return {
      page: 1,
      page_size: 20,
      count: 0,
      tableData: [],
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.onLoadAuthList();
    })
  },
  methods: {
    onLoadAuthList() {
      http_setting_load_auth_list({page: this.page, page_size: this.page_size}).then(res => {
        if (res.code == 0) {
          this.tableData = res.data.data;
          this.count = res.data.count;
        }
      })
    },
    onAddAuth() {
      let data = {
        auth_type: 'create_auth',
        ghid: ''
      };
      http_setting_add_auth_info(data).then(res => {
        if (res.code == 0) {
          let url = res.data.url;
          window.open(url, "_blank ");
        }
      })
    },
    handleClickRefresh(row, index) {
      console.log(index);
      let data = row;
      data.auth_type = 'reset_auth';
      data.ghid = row.open_id;
      http_setting_add_auth_list(data).then(res => {
        if (res.code == 0) {
          let url = res.data.url;
          window.open(url, "_blank ");
        }
      })
    },
    handleClickDelete(row, index) {
      this.$confirm('此操作将永久删除该授权, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        http_setting_delete_auth_list(row).then(res => {
          if (res.code == 0) {
            this.tableData.splice(index, 1);
          }
        })
      }).catch(() => {
      });
    },
    onChangePage(page) {
      this.page = page;
      this.onLoadAuthList();
    }
  }
}
